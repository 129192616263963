<modal #Addbookmark_Modals class="appointment-new addcustomschedule">
  <form #modalForm="ngForm" class="form">
    <div class="form-content">
      <div class="form-header">Bookmark List</div>
      <div class="form-info row">
        <div class=" col-lg-12 col-md-12 col-xs-12 no-padding-left mb-2">
          <table width="100%">
              <thead>
                <tr>
                  <th>S.No</th>
                  <th>Provider Name</th>
                  <th>Schedule Date</th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let slot of scheduleList; let i = index" [attr.id]="'slot-id-'+i">
                  <td>{{i + 1}}</td>
                  <td>{{slot.provider_name}}</td>
                  <td>{{slot.apt_date}}</td>                  
                  <td>
                    <a class="link1 btn btn-rtn btn-sm mt-2 float-right" href="javascript:void(0);" (click)="addMoreSlot(slot.id)" title="Add">Load</a>                    
                  </td>                  
                </tr>
              </tbody>
            
          </table>
        </div>
      </div>
          
    </div>
    <div class="button">
      <button type="button" class="btn btn-rtn-inv btn-90 btn-cancel" data-dismiss="modal" (click)="close()">Cancel</button>            
    </div>
  </form>
</modal>