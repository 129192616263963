 <div class="container admin-account no-padding px-0">
  <div class="simulator_wrapper mt-0">
    <div class="pageHeader px-2 py-3">
      <h3 class="mb-0 pl-3">Simulator</h3>
    </div>
    
    <div class="simulator-info">
      <div class="col-12 px-0">
        <div class="row col-12 px-0">
          <div class="row col-12 border-bottom border-#ddd py-3 no-print px-4">            
            <div class="px-0 row align-items-center col-lg-3">              
              <div class="col-3 px-0 find-appointment-line-title"> Practice:</div>
              <div class="col-9 getPractice">
                <select #select2PracticeDirective2="select2-practice2" select2-practice2 class="form-control"
                  [value]="dataSearchChart.practice" [maximumSelectionLength]="0" [closeOnSelect]="true" [multiple]="false"
                  [tags]="false" id="select2Practice" (OnSelected)="onChangePractice()">
                  <option *ngFor="let practice of practicesList; let i = index" [id]="'practice' + practice.id"
                    [value]="practice.id">{{ practice.name }}</option>
                </select>
                <div class="message-error">{{ errorResponse.practice }}</div>
                <div class="red-alert d-none d-lg-block error_message"></div>
                <div
                  class="red-alert col-md-9 col-sm-9 col-9 d-sm-block d-md-block d-lg-none d-xl-none -title px-0 error_message">
                </div>
              </div>
            </div>            
            <div class="px-0 row align-items-center col-lg-3">              
              <div class="col-3 px-0 find-appointment-line-title">Provider:</div>
              <div class="col-9 getProvider">
                <select
                #select2ProviderDirective="select2-provider"
                select2-provider 
                class="form-control"
                [value]="dataSearchChart.provider"
                [closeOnSelect]="true"
                [maximumSelectionLength]="0"
                [multiple]="false"
                id="select2Provider"
                placeholder="Select provider"
                [tags]="false"
                >
                <option value=""></option>
                <option *ngFor="let provider of providersList; let i = index" [id]="'provider' + provider.id" [value]="provider.id" >
                  {{ provider.full_name }}
                </option>
                </select>
                <div class="message-error">{{ errorResponse.provider }}</div>
                <div class="red-alert d-none d-lg-block error_message"></div>
                <div
                  class="red-alert col-md-9 col-sm-9 col-9 d-sm-block d-md-block d-lg-none d-xl-none -title px-0 error_message">
                </div>
              </div>
            
            </div>
          <!--date from-->
            <div class="px-0 row align-items-center col-lg-3">
              <div class="col-3 px-0 find-appointment-line-title">Date:</div>              
              <div class="col-9 pl-0">
                <div class="input-group">
                  <input type="text" #inputDateFrom readonly [value]="dataSearchChart['dateFrom']"
                    class="form-control calender-input bc-white" [class.input-error]="errorResponse['dateFrom']"
                    aria-describedby="basic-addon2">
                  <div class="input-group-append" #clickDateFrom>
                    <span aria-hidden="true" class="input-group-text fa fa-calendar-o"></span>
                  </div>
                </div>
                <div class="message-error">{{ errorResponse.dateFrom }}</div>
              </div>
            </div>

            <div class="px-0 row align-items-center col-lg-3">
              <div class="ml-2">
                <button class="btn load-schedule newBtn" type="button" (click)="getData()">Go</button>
              </div>
              <div class="ml-2">
                <button class="btn load-schedule newBtn" type="button" (click)="updateData()">Load Schedule</button>
              </div>
              <div class="ml-2">
                <button class="btn resetBtn load-schedule" type="button" (click)="openAddCustomModal()">Add Custom</button>
              </div>
              <div class="ml-2">
                <button id='showDataChart' class="btn resetBtn load-schedule" type="button" (click)="openBookmarkModal()">Add from Bookmark</button>
              </div>
            </div>
          </div>
        </div>
       
        <div class="row col-12 mt-3 scheduleList"  *ngIf="appointmentData?.length > 0"> 
                          
            <div class="col-12 col-xl-9 col-xl-9">
              <h6 class="text-primary mb-0">Schedule List</h6>  
              <div class="checkOption d-flex align-items-center justify-content-between">
                <div class="d-flex flex-wrap align-items-center py-2">
                  <div class="form-check mr-3">
                    <label class="form-check-label">
                      <input type="checkbox" class="form-check-input" name="amReminder" id="amReminder" [(ngModel)]="amReminder" value="1" />
                      <span class="lable-name">AM Reminder</span>
                    </label>
                  </div>
                  <div class="form-check mr-3">
                    <label class="form-check-label">
                      <input type="checkbox" class="form-check-input" name="officeStatus" id="officeStatus" [(ngModel)]="officeStatus" value="1" />
                      <span class="lable-name">Office Status</span>
                    </label>
                  </div>
                  <!--<div class="form-check mr-3">
                    <label class="form-check-label">
                      <input type="checkbox" class="form-check-input" value="" />
                      <span class="lable-name">Wait Time Changes</span>
                    </label>
                  </div>-->
                  <div class="form-check mr-3">
                    <label class="form-check-label">
                      <input type="checkbox" class="form-check-input" name="rto" id="rto" [(ngModel)]="rto" value="1" />
                      <span class="lable-name">RTO</span>
                    </label>
                  </div>
                  <div class="form-check mr-3">
                    <label class="form-check-label">                      
                      <input type="checkbox" class="form-check-input" name="openslot" id="openslot" [(ngModel)]="openslot" value="1" />     
                      <span class="lable-name">Open Slot</span>                 
                    </label>                   
                  </div>
                  <div class="form-check mr-3">                    
                    <button class="btn newBtn" type="button" (click)="processSchedule(0)">Process</button>
                    <button class="btn newBtn" type="button" (click)="processSchedule(1)">Activity</button>
                    <button class="btn newBtn" type="button" (click)="saveToBookmark()" *ngIf="bookmarked==0 && processStatus == true">Add to Bookmark</button>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <span class="switchLabel">Waittime:{{currentWT}}:</span>
                </div>
                <div class="d-flex align-items-center">
                  <span class="switchLabel">Switch to <ng-container *ngIf="currentMode == 'Demo'">debug</ng-container><ng-container *ngIf="currentMode == 'Debug'">demo</ng-container> Mode:</span>
                  <label class="switch">
                    <input type="checkbox" (click)="switchtomode()">
                    <span class="slider round"></span>
                  </label>
                 
                </div>
              </div>
              
              <div class="main-table table-responsive">
               
                <table class="table table-bordered" id="scheduleTable">
                  <thead>
                    <tr>
                      <th class="text-nowrap">S.No</th>
                      <th class="text-nowrap">Patent</th>
                      <th class="text-center text-nowrap">Plan-IN</th>
                      <th class="text-center text-nowrap">Plan-Out</th>
                      <th class="text-center text-nowrap">Arrived</th>
                      <th class="text-center text-nowrap">Actual-In</th>
                      <th class="text-center text-nowrap">Actual-Out</th>
                      <th class="text-center text-nowrap">Cancelled</th>
                      <th class="text-center text-nowrap">No-Show</th>
                      <th class="text-center text-nowrap">Action</th>
                    </tr>
                  </thead>
                  <tbody id="scheduleTable-row">
                    <ng-container *ngFor="let apt of appointmentData, index as i">
                      <tr class="schedule-row"  [ngClass]="{'schedule-active' : (apt.schedule_id == scheduleId) }" *ngIf="apt.apt_visible">
                        <td class="text-nowrap">{{ i+1 }}</td>
                        <td class="text-nowrap" [ngClass]="{'active' : (apt.schedule_id == scheduleId && ['BEGIN_APPOINTMENT','BUSINESS_FALLS_NO_WAIT_TIME','BUSINESS_FALLS','BACK_TO_OFFICE','PROCEED_AS_SCHEDULED','APPOINTMENT_PREPARATION', 'WAITLIST_NOTICE'].includes(scheduleAction))}">
                          {{ apt.patient_name }}
                        </td>
                        <td class="text-nowrap text-center">{{ apt.plan_check_in }}</td>
                        <td class="text-nowrap text-center">{{ apt.plan_check_out }}</td>
                        <td class="text-nowrap text-center" [ngClass]="{'active' : (apt.schedule_id == scheduleId && scheduleAction == 'arrived'), 'showvalue' : (apt.arrived_time_visible == true), 'hidevalue' : (apt.arrived_time_visible == false) }"><span>{{ apt.arrived_time }}</span></td>
                        <td class="text-nowrap text-center" [ngClass]="{'active' : (apt.schedule_id == scheduleId && scheduleAction == 'checkin'), 'showvalue' : (apt.actual_check_in_visible == true), 'hidevalue' : (apt.actual_check_in_visible == false) }"><span>{{ apt.actual_check_in }}</span></td>
                        <td class="text-nowrap text-center" [ngClass]="{'active' : (apt.schedule_id == scheduleId && scheduleAction == 'checkout'), 'showvalue' : (apt.actual_check_out_visible == true), 'hidevalue' : (apt.actual_check_out_visible == false) }"><span>{{ apt.actual_check_out }}</span></td>
                        <td class="text-nowrap text-center" [ngClass]="{'active' : (apt.schedule_id == scheduleId && scheduleAction == 'cancelled'), 'showvalue' : (apt.cancelled_at_visible == true), 'hidevalue' : (apt.cancelled_at_visible == false) }"><span>{{ apt.cancelled_at}}</span></td>
                        <td class="text-nowrap text-center" [ngClass]="{'active' : (apt.schedule_id == scheduleId && scheduleAction == 'noshow'), 'showvalue' : (apt.no_show_visible == true), 'hidevalue' : (apt.no_show_visible == false) }"><span>{{ apt.no_show}}</span></td>
                        <td class="text-nowrap text-center">
                          <a href="javascript:void(0);" *ngIf="apt.cancelled_at == ''" title="Waittime meter" (click)="showwaitmeter(apt)"><i class="fa fa-tachometer" aria-hidden="true"></i></a>                        
                        </td>
                      </tr>
                    </ng-container>         
                  </tbody>
                </table>              
              </div>
            </div>
            <div class="col-12 col-xl-3 col-xl-3">
              <div class="d-flex justify-content-between mb-2 playBlock">
                <ng-container *ngIf="processStatus">
                  <div class="dropdown playdrop">
                    <button class="btn btn-secondary dropdown-toggle groupbtn" type="button" id="dropdownMenu2" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                      Play
                    </button>
                    <div class="dropdown-menu" aria-labelledby="dropdownMenu2">
                      <button (click)="autoPlayBtn()" class="dropdown-item" type="button">Auto Play</button>
                      <button (click)="manualPlayBtn()" class="dropdown-item" type="button">Manual Play</button>
                    </div>
                  </div>
                  <div class="d-flex auto-play" *ngIf="playDiv.autoPlay">Interval:<input type="number" class="smallinput" [(ngModel)]="autoplayInterval" /> Seconds</div>
                  
                  <div class="d-flex auto-play" *ngIf="playDiv.autoPlay">
                    <button title="Play" (click)="playDiv.playBtn = false; playDiv.pauseBtn = true; triggerFunctionWithDelay()" class="btn groupbtn playBtn" type="button" *ngIf="playDiv.playBtn"> 
                      <i class="fa fa-play" aria-hidden="true"></i>
                   </button>
                     <button title="Pause" (click)="playDiv.pauseBtn = false; playDiv.playBtn = true; pauseActivityBtn()" class="btn groupbtn pauseBtn" type="button" *ngIf="playDiv.pauseBtn">
                      <i class="fa fa-pause" aria-hidden="true"></i>
                     </button>
                  </div>
                  <div class="d-flex manual-play" *ngIf="playDiv.manualPlay">
                     <button (click)="addPrevtActivityData()" [disabled]="currentIndex == 0" title="Previous" class="btn groupbtn mr-1" type="button"><i class="fa fa-chevron-left" aria-hidden="true"></i></button>
                     <button (click)="addNextActivityData()" [disabled]="currentIndex === activityData.length" title="Next" class="btn groupbtn" type="button"><i class="fa fa-chevron-right" aria-hidden="true"></i></button>
                  </div>
                </ng-container>                
              </div>
              <div class="activity-block">
                <div class="activity-head">                  
                  <h2>Activity</h2>
                </div>
    
                <div class="list-group">
                  <ul id="activityWrapper">
                    <ng-container *ngFor="let itemlist of currentShowItem; index as i">
                      <li class="list-group-item list-group-item-action" [ngClass]="{'activityOn' : (i == currentIndex) }">                        
                        <div class="text-box" *ngIf="currentMode=='Demo'">                          
                          <ng-container *ngIf="itemlist.activity_type == 'SCHEDULE_ACTIVITY'">
                            <ng-container *ngIf="itemlist.activity_notes == 'arrived'">{{itemlist.patient_name}}:<span class="statustxt">Arrived</span> at {{itemlist.created_at}}</ng-container>
                            <ng-container *ngIf="itemlist.activity_notes == 'checkin'">{{itemlist.patient_name}}: <span class="statustxt">Check-in</span> at {{itemlist.created_at}}</ng-container>
                            <ng-container *ngIf="itemlist.activity_notes == 'checkout'">{{itemlist.patient_name}}: <span class="statustxt">Check-out</span> at {{itemlist.created_at}}</ng-container>
                            <ng-container *ngIf="itemlist.activity_notes == 'cancelled'">{{itemlist.patient_name}}: <span class="statustxt">Cancelled</span> at {{itemlist.created_at}}</ng-container>
                            <ng-container *ngIf="itemlist.activity_notes == 'noshow'">{{itemlist.patient_name}}: <span class="statustxt">No-show</span> at {{itemlist.created_at}}</ng-container>
                            
                            <ng-container *ngIf="itemlist.activity_notes == 'booked'">                              
                              <ng-container *ngIf="itemlist.status == '1'">{{itemlist.patient_name}}: <span class="statustxt">Booked Open Slot</span> at {{itemlist.created_at}}</ng-container>
                            </ng-container>
                          </ng-container>

                          <ng-container *ngIf="itemlist.activity_type == 'BEGIN_APPOINTMENT' || itemlist.activity_type == 'NEXT_APPOINTMENT'">
                            <ng-container *ngIf="itemlist.status == '0'">{{itemlist.patient_name}}: <span class="statustxt">AM reminder</span> Not sent at {{itemlist.created_at}}</ng-container>
                            <ng-container *ngIf="itemlist.status == '1'">{{itemlist.patient_name}}: <span class="statustxt">AM reminder</span> Sent at {{itemlist.created_at}}</ng-container>
                          </ng-container>

                          <ng-container *ngIf="itemlist.activity_type == 'BUSINESS_FALLS_NO_WAIT_TIME' || itemlist.activity_type == 'BUSINESS_FALLS'">                            
                            <ng-container *ngIf="itemlist.status == '0'">{{itemlist.patient_name}}: <span class="statustxt">Office Status</span> Not sent at {{itemlist.created_at}}</ng-container>
                            <ng-container *ngIf="itemlist.status == '1'">{{itemlist.patient_name}}: <span class="statustxt">Office Status</span> Sent at {{itemlist.created_at}}</ng-container>
                          </ng-container>
                          <ng-container *ngIf="itemlist.activity_type == 'BACK_TO_OFFICE' || itemlist.activity_type == 'PROCEED_AS_SCHEDULED' || itemlist.activity_type == 'APPOINTMENT_PREPARATION'">                            
                            <ng-container *ngIf="itemlist.status == '0'">{{itemlist.patient_name}}: <span class="statustxt">RTO</span> Not sent at {{itemlist.created_at}}</ng-container>
                            <ng-container *ngIf="itemlist.status == '1'">{{itemlist.patient_name}}: <span class="statustxt">RTO</span> Sent at {{itemlist.created_at}}</ng-container>
                          </ng-container>  
                          <ng-container *ngIf="itemlist.activity_type == 'WAITLIST_NOTICE'">
                            <ng-container *ngIf="itemlist.status == '0'">{{itemlist.patient_name}}: <span class="statustxt">Open Slot notice</span> Not sent at {{itemlist.created_at}}</ng-container>
                            <ng-container *ngIf="itemlist.status == '1'">{{itemlist.patient_name}}: <span class="statustxt">Open Slot notice</span> Sent at {{itemlist.created_at}}</ng-container>
                            <a href="javascript:void(0);" (click)="bookNow(itemlist.schedule_id)">Book now</a>
                          </ng-container>                       
                        </div>

                        <div class="text-box" *ngIf="currentMode=='Debug'">                          
                          <ng-container *ngIf="itemlist.activity_type == 'SCHEDULE_ACTIVITY'">
                            <ng-container *ngIf="itemlist.activity_notes == 'arrived'">{{itemlist.patient_name}}: <span class="statustxt">Arrived</span> at {{itemlist.created_at}}</ng-container>
                            <ng-container *ngIf="itemlist.activity_notes == 'checkin'">{{itemlist.patient_name}}: <span class="statustxt">Check-in</span> at {{itemlist.created_at}}</ng-container>
                            <ng-container *ngIf="itemlist.activity_notes == 'checkout'">{{itemlist.patient_name}}: <span class="statustxt">Check-out</span> at {{itemlist.created_at}}</ng-container>
                            <ng-container *ngIf="itemlist.activity_notes == 'cancelled'">{{itemlist.patient_name}}: <span class="statustxt">Cancelled</span> at {{itemlist.created_at}}</ng-container>
                            <ng-container *ngIf="itemlist.activity_notes == 'noshow'">{{itemlist.patient_name}}: <span class="statustxt">No-show</span> at {{itemlist.created_at}}</ng-container>                            
                          </ng-container>
                          

                          <ng-container *ngIf="itemlist.activity_type == 'BEGIN_APPOINTMENT' || itemlist.activity_type == 'NEXT_APPOINTMENT'">
                            <ng-container *ngIf="itemlist.status == '0'">
                              <span class="d-flex">{{itemlist.patient_name}}: <span class="statustxt">AM reminder </span> Not Sent at {{itemlist.created_at}}</span>
                              <span class="d-flex mt-1"><span class="statustxt">Reason:</span>{{itemlist.activity_notes}}</span>
                            </ng-container>
                            <ng-container *ngIf="itemlist.status == '1'">
                              <span class="d-flex">{{itemlist.patient_name}}: <span class="statustxt">AM reminder</span> Sent at {{itemlist.created_at}}</span>
                              <span class="d-flex"><span class="statustxt">Notes:</span> {{itemlist.email_notes}} | {{itemlist.sms_notes}}</span>
                            </ng-container>
                          </ng-container>

                          <ng-container *ngIf="itemlist.activity_type == 'BUSINESS_FALLS_NO_WAIT_TIME' || itemlist.activity_type == 'BUSINESS_FALLS'">
                            <ng-container *ngIf="itemlist.status == '0'">
                              <span class="d-flex">{{itemlist.patient_name}}: <span class="statustxt">Office Status </span> Not sent at {{itemlist.created_at}}</span>
                              <span class="d-flex"><span class="statustxt">Reason:</span>{{itemlist.activity_notes}}</span>
                            </ng-container>
                            <ng-container *ngIf="itemlist.status == '1'">
                              <span class="d-flex">{{itemlist.patient_name}}: <span class="statustxt">Office Status</span> Sent at {{itemlist.created_at}}</span>
                              <span class="d-flex"><span class="statustxt">Notes:</span> {{itemlist.email_notes}} | {{itemlist.sms_notes}}</span>                         
                            </ng-container>
                          </ng-container>
                          <ng-container *ngIf="itemlist.activity_type == 'BACK_TO_OFFICE' || itemlist.activity_type == 'PROCEED_AS_SCHEDULED' || itemlist.activity_type == 'APPOINTMENT_PREPARATION'">                            
                            <ng-container *ngIf="itemlist.status == '0'">
                              <span class="d-flex">{{itemlist.patient_name}}: <span class="statustxt">RTO</span> Not sent at {{itemlist.created_at}}</span> 
                              <span class="d-flex"><span class="statustxt">Reason [{{itemlist.activity_type}}]:</span>{{itemlist.activity_notes}}</span>                                                           
                            </ng-container>
                            <ng-container *ngIf="itemlist.status == '1'">
                              <span class="d-flex">{{itemlist.patient_name}}: <span class="statustxt">RTO</span> Sent at {{itemlist.created_at}}</span>
                              <span class="d-flex"><span class="statustxt">Notes :</span> [{{itemlist.activity_type}}], {{itemlist.email_notes}} | {{itemlist.sms_notes}} | Rule :{{itemlist.notes}}</span>                              
                            </ng-container>
                          </ng-container>  

                          <ng-container *ngIf="itemlist.activity_type == 'WAITLIST_NOTICE'">
                            <ng-container *ngIf="itemlist.status == '0'">
                              <span class="d-flex">{{itemlist.patient_name}}: <span class="statustxt">Open Slot notice </span> Not Sent at {{itemlist.created_at}}</span>
                              <span class="d-flex mt-1"><span class="statustxt">Reason:</span>{{itemlist.activity_notes}}</span>
                            </ng-container>
                            
                            <ng-container *ngIf="itemlist.status == '1'">
                              <span class="d-flex">{{itemlist.patient_name}}: <span class="statustxt">Open Slot notice</span> Sent at {{itemlist.created_at}}</span>
                              <span class="d-flex"><span class="statustxt">Notes:</span> {{itemlist.email_notes}} | {{itemlist.sms_notes}}</span>
                            </ng-container>
                          </ng-container>                     
                        </div>
                      </li>
                    </ng-container>                    
                  </ul>
                </div>
                <div class="d-flex justify-content-between mb-2 mt-4 playBlock" *ngIf="processStatus">
                    <div class="d-flex"><span class="switchLabel">Waittime:{{currentWT}}:</span></div>
                    <div class="d-flex manual-play" *ngIf="playDiv.manualPlay">
                       <button (click)="addPrevtActivityData()" [disabled]="currentIndex == 0" title="Previous" class="btn groupbtn mr-1" type="button"><i class="fa fa-chevron-left" aria-hidden="true"></i></button>
                       <button (click)="addNextActivityData()" [disabled]="currentIndex === activityData.length" title="Next" class="btn groupbtn" type="button"><i class="fa fa-chevron-right" aria-hidden="true"></i></button>
                    </div>
                    <div class="d-flex auto-play" *ngIf="playDiv.autoPlay">
                      <button title="Play" (click)="playDiv.playBtn = false; playDiv.pauseBtn = true; triggerFunctionWithDelay()" class="btn groupbtn playBtn" type="button" *ngIf="playDiv.playBtn"> 
                        <i class="fa fa-play" aria-hidden="true"></i>
                     </button>
                       <button title="Pause" (click)="playDiv.pauseBtn = false; playDiv.playBtn = true; pauseActivityBtn()" class="btn groupbtn pauseBtn" type="button" *ngIf="playDiv.pauseBtn">
                        <i class="fa fa-pause" aria-hidden="true"></i>
                       </button>
                    </div>                  
                </div>
              </div>              
            </div>
        </div>
      </div>
    </div>
  </div>
</div>

<rtn-modals-app-add-custom-schedule (loadEvent)="addschedulecallback($event)"></rtn-modals-app-add-custom-schedule>
<rtn-modals-app-fillopenslot (loadEvent)="addschedulecallback($event)"></rtn-modals-app-fillopenslot>
<rtn-modals-app-waittime-meter></rtn-modals-app-waittime-meter>
<rtn-modals-app-add-from-bookmark (loadEvent)="addbookmarkschedulecallback($event)"></rtn-modals-app-add-from-bookmark>