<modal #AddSchedule_Modals class="appointment-new addcustomschedule">
  <form #modalForm="ngForm" class="form">
    <div class="form-content">
      <div class="form-header">Add Custom Schedule
        <!--<input type="file" (change)="onFileSelected($event)" class="float-right" />-->
      </div>
      <div class="form-info row">
        <div class=" col-lg-12 col-md-12 col-xs-12 no-padding-left mb-2">
          <table width="100%">
              <thead>
                <tr>
                  <th>Patient</th>
                  <th colspan="1">Plan IN</th>
                  <th colspan="1">Plan OUT</th>
                  <th colspan="1">Arrived At</th>
                  <th colspan="1">Actual IN</th>
                  <th colspan="1">Actual OUT</th>
                  <th>Cancel At</th>
                  <th>No-Show</th>
                  <th></th>
                  <th>&nbsp;</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let slot of scheduleList; let i = index" [attr.id]="'slot-id-'+i">
                  <td>Patient {{i + 1}}</td>
                  <td>                    
                    <div class="col-12 rowcontent pl-1">
                      <div class="input-group select time-picker">
                        <select class="form-control" [(ngModel)]="slot.pin_hour" name="slot_{{i}}_pin_hour">
                          <option *ngFor="let h of hours" [ngValue]="h" [selected]="slot.pin_hour == h">{{ h }}</option>
                        </select>
                        <div class="input-group-select-icon">
                          <i class="fa fa-caret-down"></i>
                        </div>
                      </div>
                      <div>:</div>
                      <div class="input-group select time-picker" [ngClass]="{ 'disabled': true }">
                        <select class="form-control" [(ngModel)]="slot.pin_minute" name="slot_{{i}}_pin_minute">
                          <option *ngFor="let m of minutes" [ngValue]="m" [selected]="slot.pin_minute == m">{{ m }}</option>
                        </select>
                        <div class="input-group-select-icon">
                          <i class="fa fa-caret-down"></i>
                        </div>
                      </div>                      
                    </div>
                  </td>
                  <td>
                    <div class="col-12 rowcontent pl-1">                      
                      <div class="input-group select time-picker">
                        <select class="form-control" [(ngModel)]="slot.pout_hour" name="slot_{{i}}_pout_hour">
                          <option *ngFor="let h of hours" [ngValue]="h" [selected]="slot.pout_hour == h">{{ h }}</option>
                        </select>
                        <div class="input-group-select-icon">
                          <i class="fa fa-caret-down"></i>
                        </div>
                      </div>
                      <div>:</div>
                      <div class="input-group select time-picker">
                        <select class="form-control" [(ngModel)]="slot.pout_minute" name="slot_{{i}}_pout_minute">
                          <option *ngFor="let m of minutes" [ngValue]="m" [selected]="slot.pout_minute == m">{{ m }}</option>
                        </select>
                        <div class="input-group-select-icon">
                          <i class="fa fa-caret-down"></i>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="col-12 rowcontent pl-1">                      
                      <div class="input-group select time-picker">
                        <select class="form-control" [(ngModel)]="slot.arrived_hour" name="slot_{{i}}_arrived_hour">
                          <option *ngFor="let h of hours" [ngValue]="h" [selected]="slot.arrived_hour == h">{{ h }}</option>
                        </select>
                        <div class="input-group-select-icon">
                          <i class="fa fa-caret-down"></i>
                        </div>
                      </div>
                      <div>:</div>
                      <div class="input-group select time-picker">
                        <select class="form-control" [(ngModel)]="slot.arrived_minute" name="slot_{{i}}_arrived_minute">
                          <option *ngFor="let m of fullminutes" [ngValue]="m" [selected]="slot.arrived_minute == m">{{ m }}</option>
                        </select>
                        <div class="input-group-select-icon">
                          <i class="fa fa-caret-down"></i>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>                    
                    <div class="col-12 rowcontent pl-1">
                      <div class="input-group select time-picker">
                        <select class="form-control" [(ngModel)]="slot.ain_hour" name="slot_{{i}}_ain_hour">
                          <option *ngFor="let h of hours" [ngValue]="h" [selected]="slot.ain_hour == h">{{ h }}</option>
                        </select>
                        <div class="input-group-select-icon">
                          <i class="fa fa-caret-down"></i>
                        </div>
                      </div>
                      <div>:</div>
                      <div class="input-group select time-picker" [ngClass]="{ 'disabled': true }">
                        <select class="form-control" [(ngModel)]="slot.ain_minute" name="slot_{{i}}_ain_minute">
                          <option *ngFor="let m of fullminutes" [ngValue]="m" [selected]="slot.ain_minute == m">{{ m }}</option>
                        </select>
                        <div class="input-group-select-icon">
                          <i class="fa fa-caret-down"></i>
                        </div>
                      </div>                      
                    </div>
                  </td>
                  <td>
                    <div class="col-12 rowcontent pl-1">                      
                      <div class="input-group select time-picker">
                        <select class="form-control" [(ngModel)]="slot.aout_hour" name="slot_{{i}}_aout_hour">
                          <option *ngFor="let h of hours" [ngValue]="h" [selected]="slot.aout_hour == h">{{ h }}</option>
                        </select>
                        <div class="input-group-select-icon">
                          <i class="fa fa-caret-down"></i>
                        </div>
                      </div>
                      <div>:</div>
                      <div class="input-group select time-picker">
                        <select class="form-control" [(ngModel)]="slot.aout_minute" name="slot_{{i}}_aout_minute">
                          <option *ngFor="let m of fullminutes" [ngValue]="m" [selected]="slot.aout_minute == m">{{ m }}</option>
                        </select>
                        <div class="input-group-select-icon">
                          <i class="fa fa-caret-down"></i>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="col-12 rowcontent pl-1">                      
                      <div class="input-group select time-picker">
                        <select class="form-control" [(ngModel)]="slot.c_hour" name="slot_{{i}}_c_hour">
                          <option *ngFor="let h of hours" [ngValue]="h" [selected]="slot.c_hour == h">{{ h }}</option>
                        </select>
                        <div class="input-group-select-icon">
                          <i class="fa fa-caret-down"></i>
                        </div>
                      </div>
                      <div>:</div>
                      <div class="input-group select time-picker">
                        <select class="form-control" [(ngModel)]="slot.c_minute" name="slot_{{i}}_c_minute">
                          <option *ngFor="let m of fullminutes" [ngValue]="m" [selected]="slot.c_minute == m">{{ m }}</option>
                        </select>
                        <div class="input-group-select-icon">
                          <i class="fa fa-caret-down"></i>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <div class="col-12 rowcontent pl-1">                      
                      <div class="input-group select time-picker">
                        <select class="form-control" [(ngModel)]="slot.n_hour" name="slot_{{i}}_n_hour">
                          <option *ngFor="let h of hours" [ngValue]="h" [selected]="slot.n_hour == h">{{ h }}</option>
                        </select>
                        <div class="input-group-select-icon">
                          <i class="fa fa-caret-down"></i>
                        </div>
                      </div>
                      <div>:</div>
                      <div class="input-group select time-picker">
                        <select class="form-control" [(ngModel)]="slot.n_minute" name="slot_{{i}}_n_minute">
                          <option *ngFor="let m of fullminutes" [ngValue]="m" [selected]="slot.n_minute == m">{{ m }}</option>
                        </select>
                        <div class="input-group-select-icon">
                          <i class="fa fa-caret-down"></i>
                        </div>
                      </div>
                    </div>
                  </td>
                  <td>
                    <input type="checkbox" [(ngModel)]="slot.fill_slot" name="slot_{{i}}_fill_slot" />
                  </td>
                  <td>
                    <a *ngIf="(scheduleList.length - 1) == i" class="link1 btn btn-rtn btn-sm mt-2 float-right" href="javascript:void(0);" (click)="addMoreSlot()" title="Add"><i class="fa fa-plus"></i></a>
                    <a *ngIf="(scheduleList.length - 1) != i" class="link1 btn btn-rtn-inv btn-sm mt-2 float-right" href="javascript:void(0);" (click)="removeSlot(i)" title="Remove"><i class="fa fa-minus"></i></a>
                  </td>                  
                </tr>
              </tbody>
            
          </table>
        </div>
      </div>
          
    </div>
    <div class="button">
      <button type="button" class="btn btn-rtn-inv btn-90 btn-cancel" data-dismiss="modal" (click)="close()">Cancel
      </button>      
      <button type="submit" class="btn btn-rtn btn-90 float-right" (click)="submitData()" autofocus>Submit
      </button>
    </div>
  </form>
</modal>