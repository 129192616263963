import { NgModule } from '@angular/core';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { SharedModule } from '@app/shared';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModule } from '@app/core';
import { BrowserModule } from '@angular/platform-browser';
import { AdminRoutingModule } from './admin-routing.module';
import { AccountComponent } from './account/account.component';
import { AddAdminComponent } from './add-admin/add-admin.component';
import { AddMasterComponent } from './add-master/add-master.component';
import { AddPatientComponent } from './add-patient/add-patient.component';
import { AddSuperUserComponent } from './add-super-user/add-super-user.component';
import { EditMasterComponent } from './edit-master/edit-master.component';
import { EditPatientComponent } from './edit-patient/edit-patient.component';
import { EditSuperUserComponent } from './edit-super-user/edit-super-user.component';
import { EhrComponent } from './ehr/ehr.component';
import { PracticeComponent } from './practice/practice.component';
import { NotificationComponent } from './notification/notification.component';
import { AddPracticeComponent } from './add-practice/add-practice.component';
import { EditPracticeComponent } from './edit-practice/edit-practice.component';
import { HeaderAdminComponent } from './header-admin/header-admin.component';
import { AdminComponent } from './admin.component';
import { ActiveModuleComponent } from './practice/active-module/active-module.component';
import { ActiveModuleModalComponent } from './practice/active-module/modals/active-module-modal.component';
import { AdminChangePasswordComponent } from './change-password/change-password.component';
import { DataLogComponent } from './data-log/data-log.component';
import { HomeModule } from '@app/home/home.module';
import {NgxMaskModule} from 'ngx-mask';
import { StatisticalAppointmentComponent } from './statistical-appointment/statistical-appointment.component';
import { ChartsModule, WavesModule } from 'angular-bootstrap-md';
import { EditAdminComponent } from './edit-admin/edit-admin.component';
import {EditManagingUserComponent} from './edit-managing-user/edit-managing-user.component';
import { AdminProfileComponent } from './admin-profile/admin-profile.component';
import { EditProviderComponent } from './edit-provider/edit-provider.component';
import { SimulatortoolComponent } from '@app/admin/simulatortool/simulatortool.component';
import { HeaderAdminSimulatorComponent } from './header-admin-simulator/header-admin-simulator.component';
import { AddCustomScheduleComponent } from './simulatortool/modals/add-custom-schedule/add-custom-schedule.component';
import { FillopenslotComponent } from './simulatortool/modals/fillopenslot/fillopenslot.component';
import { WaittimeMeterComponent } from './simulatortool/modals/waittime-meter/waittime-meter.component';
import { AddFromBookmarkComponent } from './simulatortool/modals/add-from-bookmark/add-from-bookmark.component';

@NgModule({
  imports: [
    CommonModule,
    SharedModule,
    BrowserModule,
    FormsModule,
    TranslateModule,
    ReactiveFormsModule,
    CoreModule,
    AdminRoutingModule,
    HomeModule,
    NgxMaskModule.forRoot(),
    ChartsModule,
    WavesModule.forRoot(),
  ],
  declarations: [
    AccountComponent,
    AddAdminComponent,
    AddMasterComponent,
    AddPatientComponent,
    AddSuperUserComponent,
    EditMasterComponent,
    EditPatientComponent,
    EditSuperUserComponent,
    EhrComponent,
    PracticeComponent,
    NotificationComponent,
    AddPracticeComponent,
    EditPracticeComponent,
    HeaderAdminComponent,
    AdminComponent,
    ActiveModuleComponent,
    ActiveModuleModalComponent,
    AdminChangePasswordComponent,
    DataLogComponent,
    StatisticalAppointmentComponent,
    EditAdminComponent,
    EditManagingUserComponent,
    AdminProfileComponent,
    EditProviderComponent,
    SimulatortoolComponent,
	  HeaderAdminSimulatorComponent,
	  AddCustomScheduleComponent,
	  FillopenslotComponent,
	  WaittimeMeterComponent,
	  AddFromBookmarkComponent
  ],
  providers:[],
  exports: []
})
export class AdminModule {
}
