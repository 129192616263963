<modal #wait_timemeter_Modals class="appointment-new">
    <form #modalForm="ngForm" class="form">
        <div class="form-content">
            <div class="form-header d-flex justify-content-between align-items-center">
                <span>Wait time meter</span>
                <span (click)="close()"><i class="fa fa-times" aria-hidden="true"></i></span>
            </div>
            <div class="form-info row">
                <div class=" col-12 px-0">
                    <div class="container-fluid px-0 w-100">
                        <div class="row" *ngIf="!errorMessage">
                            <div class="col-md-12 px-0 Rtn-Meter" >
                                
                                <div class="rtnmeter-main d-lg-flex justify-content-center">
                                    <div class="wait-meter w-sm-100">
                                        <div class="col-12 form meter-main px-0 py-0 d-xl-flex">
                                            <div  class="col-12 left-part px-0" [class.style-for-firefox-with-detail]="isFirefox">
                                             
                                              <div class="speedometer-img" [class.speedometer-img-2]="!appointmentDetail.isActive || appointmentDetail.hasLink">
                                                  <div class="speedometer-needle" [ngStyle]="{'-ms-transform': waitTimeMeterStyle['-ms-transform'], '-webkit-transform': waitTimeMeterStyle['-webkit-transform'], 'transform': waitTimeMeterStyle['transform']}"></div>
                                              </div>
                      
                                                <div class="duration"  *ngIf="appointmentDetail.waitingTime != null && appointmentDetail.waitingTime != -1">
                                                    <div class="d-flex justify-content-center align-items-center">
                                                        <h2>{{ appointmentDetail.waitingTime }}</h2>
                                                    </div>
                                                    <div class="d-flex justify-content-center align-items-center">
                                                        <p class="mb-0">{{ _utils.generateUnitDisplay(appointmentDetail.waitingTime, 'MINUTE') }}</p>
                                                    </div>
                                                </div>
                                                  <div class="realtime d-flex justify-content-center align-items-center px-2" *ngIf="appointmentDetail.notice && (appointmentDetail.waitingTime == null || appointmentDetail.waitingTime == -1)">
                                                      <h4 class="mb-0">{{ appointmentDetail.notice }}</h4>
                                                  </div>
                                            </div>                      
                                        </div>
                                    </div>
                                </div>
                            </div>
                            
                        </div>
                        <div class="row mt-5" *ngIf="errorMessage">
                          <div class="col-md-12" >
                              <div class="errorsection">
                                  <p class="label-danger">{{ errorMessage }}</p>
                              </div>        
                          </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="button">
            <span>Current Time : {{currentTimeStr}}</span>
            <input type="button" value="Next" (click)="changeWaitmeter()" />
        </div>
    </form>
    
</modal>
