import { Component, Output, ViewChild, OnInit, AfterViewInit, OnDestroy,EventEmitter, ViewEncapsulation } from '@angular/core';
import { ModalComponent } from '@app/shared/ng2-bs3-modal';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import * as moment from 'moment';
import { AdminRestService } from '@app/admin/admin.service';
import { Subscription } from 'rxjs';
import { AppointmentsService } from '@app/appointments/appointments.service';

declare let jQuery: any;

@Component({
  moduleId: module.id,  
  selector: 'rtn-modals-app-fillopenslot',  
  templateUrl: './fillopenslot.component.html',
  styleUrls: ['./fillopenslot.component.scss'],
  encapsulation: ViewEncapsulation.None
})

export class FillopenslotComponent implements OnInit {
  
  @ViewChild('Fillopenslot_Modals') modal: ModalComponent;

  hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12',
    '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
  minutes = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];
  errorMessage: string;
  errorProvider: string;

  //scheduleList: any = [];
  scheduleListObject: ScheduleList = new ScheduleList();
  filleopenslotScheduleSubscriber: Subscription;
  selectedPracticeId:any = 0;
  selectedProviderId:any = 0;
  selectedDateFrom:any = '';
  scheduleinfo:any={};
  searchDisabled = true;
  patients: any = [];
  patient_id: number;
  patient_name:any='';

  searchPatientSubscriber: Subscription;

  @Output() loadEvent = new EventEmitter<object>();

  constructor(
    private _loadingIndicatorService: LoadingIndicatorService,
    private _adminService: AdminRestService,
    private Service: AppointmentsService,
  ) { }
  ngOnInit() {
  }
  ngAfterViewInit() {

  }
  ngOnDestroy() {

  }
  onKeyUp() {
    //this.requireMessage.patient_id = null;
    if (jQuery('#search-field-new-appointment').val() === '') {
      this.patient_id = null;
      this.searchDisabled = true;
      jQuery('.search').removeClass('open');
    } else {
      this.searchDisabled = false;
    }
  }
  open(aptinfo:any, practiceID:any, providerID:any, dateFrom:any) {
    this.modal.open(); 

    this.selectedPracticeId = practiceID;
    this.selectedProviderId = providerID;

    //this.scheduleList = [];

    this.scheduleinfo = aptinfo;
    /*if (aptinfo.initial_check_in != undefined){
      let initial_check_in = aptinfo.initial_check_in.split('T');
      this.selectedDateFrom = initial_check_in[0];
    }*/
    this.selectedDateFrom = dateFrom;
    console.log(aptinfo);

    this.scheduleListObject = new ScheduleList();  
    this.scheduleListObject.cid = aptinfo.id;

    if (aptinfo.plan_check_in != '') {
      var pin = aptinfo.plan_check_in.split(':');

      this.scheduleListObject.pin_hour = pin[0];
      this.scheduleListObject.pin_minute = pin[1];
    }
    if (aptinfo.plan_check_out != '') {
      var pout = aptinfo.plan_check_out.split(':');

      this.scheduleListObject.pout_hour = pout[0];
      this.scheduleListObject.pout_minute = pout[1];
    }
  }
  close(){

  }  
  submitData(){
    
    console.log(this.scheduleListObject);

    

    let tempdata = this.scheduleListObject;
    
    let formdata:any = { cid:tempdata.cid, patient_id:tempdata.patient_id, addtowaitlist: tempdata.addtowaitlist, 
        plan_in: null, plan_out: null, actual_in: null, actual_out: null, cancel_at: null, no_show: null, arrived_at:null };

    // Plan in
    if ((tempdata.pin_hour && tempdata.pin_minute) && (tempdata.pout_hour && tempdata.pout_minute)) {
      let pinhour = parseInt(tempdata.pin_hour);
      let pinminute = parseInt(tempdata.pin_minute);

      let pouthour = parseInt(tempdata.pout_hour);
      let poutminute = parseInt(tempdata.pout_minute);

      formdata.plan_in = moment(this.selectedDateFrom, 'MMMM DD, YYYY').hour(pinhour).minute(pinminute).second(0).utc().format('YYYY-MM-DDTHH:mm:ssZZ');          
      formdata.plan_out = moment(this.selectedDateFrom, 'MMMM DD, YYYY').hour(pouthour).minute(poutminute).second(0).utc().format('YYYY-MM-DDTHH:mm:ssZZ'); 
      
      console.log('selectedDateFrom:' + this.selectedDateFrom);
      console.log('pinhour:' + pinhour + ' : ' + pinminute + ' = ' + formdata.plan_in + ' = ' + formdata.plan_out);

    }
    // Actual
    if (tempdata.ain_hour && tempdata.ain_minute && tempdata.aout_hour && tempdata.aout_minute) {
      let ainhour = parseInt(tempdata.ain_hour);
      let ainminute = parseInt(tempdata.ain_minute);

      let aouthour = parseInt(tempdata.aout_hour);
      let aoutminute = parseInt(tempdata.aout_minute);

      formdata.actual_in = moment(this.selectedDateFrom, 'MMMM DD, YYYY').hour(ainhour).minute(ainminute).second(0).utc().format('YYYY-MM-DDTHH:mm:ssZZ');          
      formdata.actual_out = moment(this.selectedDateFrom, 'MMMM DD, YYYY').hour(aouthour).minute(aoutminute).second(0).utc().format('YYYY-MM-DDTHH:mm:ssZZ');                    
    }
    // Cancel
    if (tempdata.c_hour && tempdata.c_minute) {
      let chour = parseInt(tempdata.c_hour);
      let cminute = parseInt(tempdata.c_minute);

      formdata.cancel_at = moment(this.selectedDateFrom, 'MMMM DD, YYYY').hour(chour).minute(cminute).second(0).utc().format('YYYY-MM-DDTHH:mm:ssZZ');                    
    }
    // No show
    if (tempdata.n_hour && tempdata.n_minute) {
      let nhour = parseInt(tempdata.n_hour);
      let nminute = parseInt(tempdata.n_minute);

      formdata.no_show = moment(this.selectedDateFrom, 'MMMM DD, YYYY').hour(nhour).minute(nminute).second(0).utc().format('YYYY-MM-DDTHH:mm:ssZZ');             
    }
    // arrived
    if (tempdata.arrived_hour && tempdata.arrived_minute) {
      let arrivedhour = parseInt(tempdata.arrived_hour);
      let arrivedminute = parseInt(tempdata.arrived_minute);
      formdata.arrived_at = moment(this.selectedDateFrom, 'MMMM DD, YYYY').hour(arrivedhour).minute(arrivedminute).second(0).utc().format('YYYY-MM-DDTHH:mm:ssZZ');             
    }

    console.log(formdata);

    this._loadingIndicatorService.start();
    if (this.filleopenslotScheduleSubscriber) {
        this.filleopenslotScheduleSubscriber.unsubscribe();
    }

    const params = {
      practiceId : this.selectedPracticeId,
      providerId : this.selectedProviderId,
      dateFrom : this.selectedDateFrom,      
    };
    
    this.filleopenslotScheduleSubscriber = this._adminService.fillOpenslotnotification({newData : formdata}).subscribe(
        (success: any) => {
            this._loadingIndicatorService.stop();
            this.modal.close();
            this.loadEvent.emit(params);
        },
        (error: any) => {
            this._loadingIndicatorService.stop();
            if (error.error.error_code === 'RC152' || error.error.error_code === 'RC047' || error.error.error_code === 'RC033') {
              this.errorMessage = error.error.error_messages;
            }
        });
  }
  searchPatientClicked(event: any) {
    this.patients = [];
    this.errorMessage = null;
    //this.errorMessageBook = null;
    
      if (this.searchPatientSubscriber) {
        this.searchPatientSubscriber.unsubscribe();
      }

      this.searchPatientSubscriber = this.Service.searchPatient(this.scheduleListObject.patient_name)
        .subscribe(
          (success: any) => {
            if (success.error_code === 'RC000') {
              success.patients.forEach((item: any) => {
                const patient = new PatientInfoClass();
                patient.id = item.id;
                patient.first_name = item.first_name;
                patient.last_name = item.last_name;
                patient.dob = item.dob !== null ? moment(item.dob, 'YYYY-MM-DD').format('MMMM DD, YYYY') : null;
                patient.phone_mobile = item.phone_mobile;
                patient.is_family = item.is_family;
                jQuery(event.srcElement).closest('.input-group').addClass('open');
                jQuery('.result.dropdown-menu').css({
                  'width': jQuery(event.srcElement).closest('.input-group').width() + 15,
                  'margin-left': '-15px'
                });
                this.patients.push(patient);
              });
            }
            if (success.error_code === 'RC061') {
              jQuery(event.srcElement).closest('.input-group').addClass('open');
              this.errorMessage = success.error_messages;
            }
          },
          (error: any) => {
            const e = error.error;
            if (e.error_code === 'RC061') {
              this.errorMessage = e.error_messages;
            }
          }
        );
    
  }
  
  getInfoByNameClicked(event: any, id: number, first_name: string, last_name: string, dob: string, is_family: boolean) {
    this.scheduleListObject.patient_id = id;  
    this.scheduleListObject.patient_name = first_name + ' ' + last_name + ' - ' + dob;  
    jQuery(event.srcElement).closest('.input-group').removeClass('open');    
  }

  getInfoByNumberClicked(event: any, id: number, phone_mobile: number, first_name: string, last_name: string) {
    this.scheduleListObject.patient_id = id;    
    this.scheduleListObject.patient_name = first_name + ' ' + last_name + ' - ' + phone_mobile; 
    jQuery(event.srcElement).closest('.input-group').removeClass('open');
  }
}
export class ScheduleList {
  cid:any=0;
  patient_id:any=0;
  patient_name:string='';
  pin_hour: string='';
  pin_minute: string='';

  pout_hour: string='';
  pout_minute: string='';

  ain_hour: string='';
  ain_minute: string='';

  aout_hour: string='';
  aout_minute: string='';

  c_hour: string='';
  c_minute: string='';

  n_hour: string='';
  n_minute: string='';
  arrived_hour: string='';
  arrived_minute: string='';
  addtowaitlist:boolean=true;
}
export class PatientInfoClass {
  id: number;
  first_name: string;
  last_name: string;
  dob: string;
  phone_mobile: number;
  is_family: boolean;
}
