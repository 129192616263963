// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

// `.env.ts` is generated by the `npm run env` command
import env from './.env';

export const environment = {
  production: false,
  version: env.npm_package_version + '-dev',
  socketUrl : 'socket.rtnstaging.com',
  //socketUrl : '54.89.181.222:9009',
  serverUrl: 'https://sit5-backend.rtnstaging.com/api',
  defaultLanguage: 'en-US',
  supportedLanguages: [
    'en-US',
    'fr-FR'
  ],
  timeoutActivity: 30*60,
  iv: '7627922264353091',
  socketEnabled: false
};
