import { Component, OnInit, ViewChild, AfterViewInit, ElementRef } from '@angular/core';

import { Subscription } from 'rxjs';
import { Select2PracticeDirective2 } from '@app/shared/form/select2-practice.directive2';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';

import { DataLogsService } from '@app/data-logs/data-logs.service';
import * as moment from 'moment';
import { AlertService } from '@app/shared/alert';
import { Select2ProviderDirective } from '@app/shared/form/select2-provider.directive';
import { AdminRestService } from '@app/admin/admin.service';
import { Utils } from '@app/shared/utils';
import { AddCustomScheduleComponent } from '@app/admin/simulatortool/modals/add-custom-schedule/add-custom-schedule.component';
import { FillopenslotComponent } from '@app/admin/simulatortool/modals/fillopenslot/fillopenslot.component';
import { WaittimeMeterComponent } from '@app/admin/simulatortool/modals/waittime-meter/waittime-meter.component';
import { ModalComponent } from '@app/shared/ng2-bs3-modal';
import { AddFromBookmarkComponent } from '@app/admin/simulatortool/modals/add-from-bookmark/add-from-bookmark.component';

declare let jQuery: any;

@Component({
  selector: 'app-simulatortool',
  templateUrl: './simulatortool.component.html',
  styleUrls: ['./simulatortool.component.scss']
})
export class SimulatortoolComponent implements OnInit, AfterViewInit {
  
  currentDate: any = moment();
  f_practiceId= 0;
  f_providerId = 0;
  f_dateFrom:any;
  getListPracticeSubscriber: Subscription;
  getListProviderSubscriber: Subscription;
  dataSearchChart: dataStatistic = new dataStatistic();
  errorResponse: dataStatistic = new dataStatistic();
  practiceTypeSubscriber: Subscription;
  providerTypeSubscriber: Subscription;
  public practicesList: any = [];
  public providersList: any = [];
  public appointmentData: any = [];

  playDiv = {
    autoPlay: false,
    manualPlay: false,
    playBtn: true,
    pauseBtn: false,
  };
  conversation: any[] = [];
  currentIndex: number = -1;
  currentItem: any = [];
  currentShowItem: any = [];
  
  intervalId: any;
  activityData: any = [];
  waitmeterData: any = [];
  scheduleId:number = 0;
  scheduleAction:string = '';
 
  maxDate:any;
  @ViewChild('select2PracticeDirective2') PracticeSelector: Select2PracticeDirective2;
  @ViewChild('select2ProviderDirective') ProviderSelector: Select2ProviderDirective;
  @ViewChild('inputDateFrom') inputPractice: ElementRef;
  @ViewChild('inputDateFrom') inputProvider: ElementRef;
  @ViewChild('inputDateFrom') inputDateFrom: ElementRef;
  @ViewChild('clickDateFrom') clickDateFrom: ElementRef;
  @ViewChild('inputDateTo') inputDateTo: ElementRef;
  @ViewChild('clickDateTo') clickDateTo: ElementRef;

  processStatus:boolean=false;
  @ViewChild(AddCustomScheduleComponent) addCustomScheduleComponent: AddCustomScheduleComponent;
  @ViewChild(FillopenslotComponent) fillopenslotComponent: FillopenslotComponent;
  @ViewChild(WaittimeMeterComponent) waittimeMeterComponent: WaittimeMeterComponent;
  @ViewChild(AddFromBookmarkComponent) addFromBookmarkComponent: AddFromBookmarkComponent;

  amReminder:boolean=false;
  officeStatus:boolean=false;
  rto:boolean=false;
  openslot:boolean=false;
  currentMode:string='Demo';
  currentWT: number = 0;
  autoplayInterval: number=1;

  @ViewChild('Fillopenslot_Modals') modal: ModalComponent;
  bookmarked:number=0;

  constructor(
    private _alertService: AlertService,
    private _loadingIndicatorService: LoadingIndicatorService,
    private _adminService: AdminRestService,
    private _datalogsService: DataLogsService,
    private _utils: Utils,
  ) {
    this.dataSearchChart.dateFrom = moment().subtract(1, "days").format('LL');
  }

  ngOnInit() {
    this.PracticeSelector.clearData();
    this.ProviderSelector.clearData();
    this.PracticeSelector.resetSelect2(jQuery('#select2Practice'));
    this.ProviderSelector.resetSelect2(jQuery('#select2Provider'));
    const _self = this;
    _self._loadingIndicatorService.start();
    _self.getListPracticeSubscriber = _self._adminService.getPracticeList().subscribe(
        (success: any) => {
            _self.practicesList = success.practices;
            const firstPractice = (_self.practicesList.length > 0) ? _self.practicesList[0].id : '';
            _self.getProviderByPractice(firstPractice);
            this._loadingIndicatorService.stop();
        });
    
  }
  ngOnDestroy() {

  }
  removeMsgErr() {
    this.errorResponse.provider = null;
    this.ProviderSelector.inputError = false;
    this.ProviderSelector.changeBorderColorProvider();
  }

  onChangePractice() {
      this.ProviderSelector.clearData();
      this.ProviderSelector.resetSelect2(jQuery('#select2Provider'));
      this.removeMsgErr();
      const practice = this.PracticeSelector.getData();
      if (practice.length !== 0) {
          this.getProviderByPractice(practice);
          if (this.providersList.length > 0) {
              this.ProviderSelector.updateData(this.providersList);

          }
      } else {
          this.ProviderSelector.clearData();
      }
  }
  getProviderByPractice(practiceId: any) {

    this._loadingIndicatorService.start();
    if (this.providerTypeSubscriber) {
        this.practiceTypeSubscriber.unsubscribe();
    }
    this.getListProviderSubscriber = this._datalogsService.getListProviderByPractice(practiceId).subscribe(
        (success: any) => {
            if (success.error_code === 'RC000') {
                this.providersList = [];
                success.providers.forEach((item: any) => {
                    item.full_name
                        = item.first_name + ((item.middle_name == null)
                            ? (' ')
                            : (' ' + item.middle_name + ' ')) + item.last_name;
                    this.providersList.push(item);
                });


            }
            this._loadingIndicatorService.stop();
        },
        (error: any) => {

            this._loadingIndicatorService.stop();
        }
    );
  }
  ngAfterViewInit(): void {
    const zero_oclock = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day'));
    const now = this._utils.formatTimeForWebServiceCall(this.currentDate.clone());
    const startCurrentTime = this._utils.formatTimeForWebServiceCall(this.currentDate.clone().startOf('day').add(2, 'hours'));
    if (now >= zero_oclock && now < startCurrentTime) {
        this.maxDate = moment().subtract(2, 'days').format('LL');
        this.dataSearchChart.dateFrom = moment().subtract(2, 'days').format('LL');
        //this.dataSearchChart.dateTo = moment().subtract(2, 'days').format('LL');
    }else{
        this.maxDate = moment().subtract(1, 'days').format('LL');
        this.dataSearchChart.dateFrom = moment().subtract(1, 'days').format('LL');
        //this.dataSearchChart.dateTo = moment().subtract(1, 'days').format('LL');
    }
    const _self = this;
    jQuery(_self.inputDateFrom.nativeElement).datepicker({
        dateFormat: 'MM dd, yy',
        numberOfMonths: 2,
        showButtonPanel: true,
        maxDate: moment(this.maxDate).toDate(),
        changeMonth: true,
        changeYear: true,
        onSelect: (selected: any) => {
            _self.dataSearchChart.dateFrom = jQuery(_self.inputDateFrom.nativeElement).val();
        }
    });
    jQuery(_self.inputDateFrom.nativeElement).datepicker("setDate", this.dataSearchChart.dateFrom);
    jQuery(_self.clickDateFrom.nativeElement).click(() => {
        jQuery(_self.inputDateFrom.nativeElement).datepicker('show');
    });    
  }
  validatePractice(message: any) {
    if (this.PracticeSelector.getData() == null || this.PracticeSelector.getData().length == 0) {
        this.errorResponse.practice = message;
        this.PracticeSelector.inputError = true;
        this.PracticeSelector.changeBorderColorPractice();
        return false;
    } else {
        this.errorResponse.practice = null;
        this.PracticeSelector.inputError = false;
        this.PracticeSelector.changeBorderColorPractice();
        return true;
    }
  }

  validateProvider(message: any) {
      if (this.ProviderSelector.getData() == null || this.ProviderSelector.getData().length === 0) {
          this.errorResponse.provider = message;
          this.ProviderSelector.inputError = true;
          this.ProviderSelector.changeBorderColorProvider();
          return false;
      } else {
          this.removeMsgErr();
          return true;
      }
  }

  validate() {
      const validatePractice = this.validatePractice('This field is required');
      const validateProvider = this.validateProvider('This field is required');
      const validateDateFrom = true;
      if (validatePractice && validateProvider && validateDateFrom) {
          return true;
      }
      return false;
  }
  getData(){
    const practiceID = this.PracticeSelector.getData();
    const providerID = this.ProviderSelector.getData();
    const dateFrom = this.dataSearchChart.dateFrom;    

    if (this.validate()) {
        this.getProviderschedule(dateFrom, practiceID, providerID);
    } else {        
        return;
    }
  }
  updateData() {
    
    const practiceID = this.PracticeSelector.getData();
    const providerID = this.ProviderSelector.getData();
    const dateFrom = this.dataSearchChart.dateFrom;    

    if (this.validate()) {        
        this.loadSchedule(dateFrom, practiceID, providerID);
        //this.getProviderschedule(dateFrom, practiceID, providerID);
    } else {        
        return;
    }

  }
  resetData(){

  }
  openAddCustomModal(){
    const practiceID = this.PracticeSelector.getData();
    const providerID = this.ProviderSelector.getData();
    const dateFrom = this.dataSearchChart.dateFrom;  
    if (this.validate()) {  
      this.f_practiceId = practiceID;
      this.f_providerId = providerID;
      this.f_dateFrom = dateFrom;

      this.addCustomScheduleComponent.open(practiceID, providerID, dateFrom);            
    } else {        
        return;
    }
  }
  loadSchedule(dateFrom: any, practiceID: any, providerID: any){
    this._loadingIndicatorService.start();        
    this._adminService.loadProviderschedule(dateFrom, practiceID, providerID).subscribe(
            (success: any) => {
                this._loadingIndicatorService.stop();                
                //console.log(success);
                this.getProviderschedule(dateFrom, practiceID, providerID);
            },
            (error: any) => {
                // todo
                this._loadingIndicatorService.stop();
                if (error.error_code == 'RC082'){
                  alert(error.error_messages);
                }                
            }
        );
  }
  getProviderschedule(dateFrom: any, practiceID: any, providerID: any){
    this.processStatus = false;
    this.activityData = [];
    
    this._loadingIndicatorService.start();        
    this._adminService.getProviderschedule(dateFrom, practiceID, providerID).subscribe(
            (success: any) => {
                this._loadingIndicatorService.stop();
                this.appointmentData = success.data_schedules;
                this.bookmarked = success.bookmarked;
                //this.activityData = success.activity_lists;
            },
            (error: any) => {
                // todo
                this._loadingIndicatorService.stop();
            }
        );
  }
  processSchedule(ismode:any){
    
    this.processStatus = false;
    this.activityData = [];
    this.scheduleId = 0;
    this.scheduleAction = '';

    this.playDiv.autoPlay = false;
    this.playDiv.manualPlay = false;
    this.currentIndex = -1;

    this.currentShowItem = [];
    this.currentItem = [];
    this.currentWT = 0;
    const practiceID = this.PracticeSelector.getData();
    const providerID = this.ProviderSelector.getData();
    const dateFrom = this.dataSearchChart.dateFrom;
    
    const amReminder = (this.amReminder) ? 1:0;
    const officeStatus = (this.officeStatus) ? 1:0;
    const rto = (this.rto) ? 1: 0;
    const openslot = (this.openslot) ? 1: 0;

    this._loadingIndicatorService.start();        
    this._adminService.processProviderSchedule(dateFrom, practiceID, providerID, amReminder, officeStatus, rto, openslot, ismode).subscribe((success: any) => {
            this._loadingIndicatorService.stop();
            if (ismode == 1){
              this.processStatus = true;
              this.activityData = success.activity_lists;            
              this.waitmeterData = success.waitmeter;     
            } else {
              alert(success.error_messages);
            }
                  
    },(error: any) => {      
      this._loadingIndicatorService.stop();
    });

  }
  autoPlayBtn() {
    this.playDiv.autoPlay = true,
    this.playDiv.manualPlay = false
  }
  manualPlayBtn() {
    this.playDiv.autoPlay = false,
    this.playDiv.manualPlay = true
  }
  // For Next Button.
  addNextActivityData() {

    if (this.currentIndex < this.activityData.length) {
      this.currentIndex++;
    } 

    this.scrollActiveList();

    let tempdata = this.activityData[this.currentIndex];

    if (tempdata['booknow_clicked'] == 'No'){
      
      this.activityData[this.currentIndex]['booknow_clicked'] = 'Yes';
      let id = tempdata['schedule_id'];
      var idx = this.appointmentData.findIndex(function(aptdata:any) {
        return aptdata.id == id;
      });
      this.appointmentData[idx].apt_visible = true;
      console.log(idx);
    }
    
    let that = this;

    this.scheduleId = tempdata['schedule_id'];
    
    this.scheduleAction = (tempdata['activity_type'] == 'SCHEDULE_ACTIVITY') ? tempdata['activity_notes']:tempdata['activity_type'];

    this.currentWT = tempdata['wt_value'];
    
    //console.log(this.scheduleId );

    var index = -1;
    
    /*var filteredObj = this.currentItem.find(function(item:any, i:any){
      if (item.schedule_id === that.scheduleId && item.activity_notes == that.scheduleAction){
        index = i;
        return i;
      }
    });*/

    var filteredObj = this.currentItem.find(function(item:any, i:any){
      if (item.id === tempdata['id']){
        index = i;
        return i;
      }
    });

    if (index === -1) {
      this.currentItem.push(this.activityData[this.currentIndex]);
    }

    var index = -1;
    
    var filteredObj1 = this.appointmentData.find(function(item:any, i:any){
      if (item.schedule_id === that.scheduleId){
        index = i;
        return i;
      }
    });

    this.appointmentData[index]['arrived_time_visible'] = (this.scheduleAction == 'arrived') ? true : this.appointmentData[index]['arrived_time_visible'];
    this.appointmentData[index]['actual_check_in_visible'] = (this.scheduleAction == 'checkin') ? true : this.appointmentData[index]['actual_check_in_visible'];
    this.appointmentData[index]['actual_check_out_visible'] = (this.scheduleAction == 'checkout') ? true : this.appointmentData[index]['actual_check_out_visible'];
    this.appointmentData[index]['cancelled_at_visible'] = (this.scheduleAction == 'cancelled') ? true : this.appointmentData[index]['cancelled_at_visible'];
    this.appointmentData[index]['no_show_visible'] = (this.scheduleAction == 'noshow') ? true : this.appointmentData[index]['no_show_visible'];

    //this.appointmentData[index]['apt_visible'] = (this.scheduleAction == 'booked') ? true : this.appointmentData[index]['apt_visible'];
      
    
    console.log('Next - Idx:' + this.currentIndex + ' | scheduleId= ' + this.scheduleId + '| scheduleAction=' + this.scheduleAction);

    // After end of data auto mode list will be stop.
    if (this.currentIndex >= this.activityData.length) {
      this.pauseActivityBtn();
      this.playDiv.playBtn = true,
      this.playDiv.pauseBtn = false
    }
  
    this.currentShowItem = this.currentItem;
  }
// For Previous Button.
  addPrevtActivityData() {
    if (this.currentIndex > 0) {
      this.currentIndex--;
    }

    this.scrollActiveList();

    let tempdata = this.activityData[this.currentIndex];
    let that = this;

    this.scheduleId = tempdata['schedule_id'];
    
    this.scheduleAction = (tempdata['activity_type'] == 'SCHEDULE_ACTIVITY') ? tempdata['activity_notes']:tempdata['activity_type'];

    console.log('Prev - Idx:' + this.currentIndex + ' | scheduleId= ' + this.scheduleId + '| scheduleAction=' + this.scheduleAction);
        
  }
  scrollActiveList(){
    setTimeout(function(){
      let _mainDivBot = jQuery("#activityWrapper");
      let _activeDivBot = jQuery(".activityOn");
      let scrollDiv = parseInt(_mainDivBot.offset().top);
      let offsetactiveList = parseInt(_activeDivBot.offset().top);
      _mainDivBot.scrollTop(_mainDivBot.scrollTop() + (offsetactiveList - scrollDiv));
    }, 200); 
    
    setTimeout(function(){
      let scheduleactive = jQuery(".schedule-active");
      jQuery('html, body').scrollTop(scheduleactive.offset().top);
    }, 500);
  }
// For Auto Play.
  triggerFunctionWithDelay() {
    if (this.currentIndex >= this.activityData.length) {
      alert('No activity available');
      this.playDiv.playBtn = true,
      this.playDiv.pauseBtn = false
    }
    else {
      this.intervalId = setInterval(() => { this.addNextActivityData(); }, (this.autoplayInterval*1000));
    }
  }
// For Pause.
  pauseActivityBtn() {
    clearInterval(this.intervalId);
    this.intervalId = null
  }
  addschedulecallback($e:any){    
    this.getProviderschedule(this.f_dateFrom, this.f_practiceId, this.f_providerId);
  }
  addbookmarkschedulecallback($e:any){   
    console.log($e);
    console.log($e.practiceId);

    this.getProviderschedule($e.startTime, $e.practiceId, $e.providerId);
  }
  switchtomode(){
    this.currentMode = (this.currentMode == 'Demo') ? 'Debug' : 'Demo';
  }
  fillopenSlot(apt:any){
    const practiceID = this.PracticeSelector.getData();
    const providerID = this.ProviderSelector.getData();
    const dateFrom = this.dataSearchChart.dateFrom;
    
    this.f_practiceId = practiceID;
    this.f_providerId = providerID;
    this.f_dateFrom = dateFrom;
    
    this.fillopenslotComponent.open(apt, practiceID, providerID, dateFrom);
  }
  showwaitmeter(apt:any){     
    let curdata = this.activityData[this.currentIndex];
    
    //console.log(curdata);

    this.waittimeMeterComponent.open(apt.schedule_id);
  }
  bookNow(id:any){
    let len = this.appointmentData.length;

    var index = this.appointmentData.findIndex(function(aptdata:any) {
      return aptdata.filled == id;
    });
    let that = this;
    setTimeout(function(){
      that.appointmentData[index].apt_visible = true;
      that.addNextActivityData();
    }, 500);
  }
  saveToBookmark(){
    const practiceID = this.PracticeSelector.getData();
    const providerID = this.ProviderSelector.getData();
    const dateFrom = this.dataSearchChart.dateFrom;

    this._loadingIndicatorService.start();        
    this._adminService.saveToBookmark(dateFrom, practiceID, providerID).subscribe((success: any) => {
      this._loadingIndicatorService.stop();      
      this.bookmarked=1;
    },(error: any) => {      
      this._loadingIndicatorService.stop();
    });
  }  
  openBookmarkModal(){
    this.addFromBookmarkComponent.open(); 
  }
}
export class dataStatistic {
  practice: any = null;
  provider: any = null;
  dateFrom: any = null;  
}
