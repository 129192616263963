<footer>
  <section class="footerTop py-4">
      <div class="container">
          <div class="row">
              <div class="col-lg-7 col-md-12">
                  <h2>
                      Experience The Evolution<br>
                      Of Your Business
                  </h2>
                  <p class="dis">Real Time Neighborhood is a ground-breaking venture established with the sole vision of revolutionizing the healthcare experience for providers and patients alike</p>
                  <ul class="d-flex justify-content-between flex-md-row flex-column cntactInfo">
                      <!--<li class="d-flex flex-row">
                        <span class="icon"><img src="{{ src }}call.png" alt="Call Icon"/></span>
                        <div class="d-flex flex-column">
                           <span class="head">Call Us Everyday</span>
                           <span><a href="callto:+1235568824">+123 556 8824</a></span>
                        </div>
                      </li>-->
                      <li class="d-flex flex-row">
                          <span class="icon"><img src="{{ src }}email.png" alt="Call Icon"/></span>
                          <div class="d-flex flex-column">
                              <span class="head">Email Drop Us</span>
                              <span><a href="mailto:info@realtimeneighborhood.com">info@realtimeneighborhood.com</a></span>
                          </div>
                        </li>
                     
                  </ul>
                  <img src="{{ src }}footerLogo.png" alt="Footer Logo" style="visibility: hidden;" />
              </div>
              <div class="col-lg-5 col-md-12 position-relative">
                  <div class="freeConsultation">
                      <h6>Get in touch</h6>
                      <h3>Free Consultation</h3>
                      <form novalidate #supportForm="ngForm" (ngSubmit)="getInTouch()">
                          <div class="mb-3">
                            <label for="support_name" class="d-none">Name</label>
                            <input type="text" id="support_name" class="form-control" placeholder="Your Name" name="support_name" [(ngModel)]="customerSupportModel.support_name" (keyup)="onKeyUp()" autocomplete="off"/>
                            <span class="text-danger" *ngIf="requireMessageSupport.support_name">{{requireMessageSupport.support_name}}</span>
                          </div>
                          <div class="mb-3">
                            <label for="support_email" class="d-none">Email</label>
                            <input type="email" id="support_email" class="form-control" placeholder="Your e-mail" name="support_email" [(ngModel)]="customerSupportModel.support_email" (keyup)="onKeyUp()" autocomplete="off"/>
                            <span class="text-danger" *ngIf="requireMessageSupport.support_email">{{requireMessageSupport.support_email}}</span>
                          </div>
                          <div class="mb-3">
                              <label for="support_subject" class="d-none">subject</label>
                              <input type="text" id="support_subject" class="form-control" placeholder="Subject" name="support_subject" [(ngModel)]="customerSupportModel.support_subject" (keyup)="onKeyUp()" autocomplete="off"/>
                              <span class="text-danger" *ngIf="requireMessageSupport.support_subject">{{requireMessageSupport.support_subject}}</span>
                          </div>
                          <div class="mb-3">
                              <label for="support_message" class="d-none">Message</label>
                              <textarea id="support_message" class="form-control" placeholder="Message" name="support_message" [(ngModel)]="customerSupportModel.support_message" (keyup)="onKeyUp()" autocomplete="off"></textarea>
                              <span class="text-danger" *ngIf="requireMessageSupport.support_message">{{requireMessageSupport.support_message}}</span>
                          </div>
                          <button type="submit" [disabled]="formSubmitted">
                             <span>Free Consultant</span>
                             <img src="{{ src }}RightArrow.svg" alt="Arrow Icon"/>
                          </button>
                          <div class="message-error" *ngIf="errorMessageBook">{{ errorMessageBook }}</div>
                     </form>
                  </div>
              </div>
          </div>
        
      </div>
  </section>
  <section class="footerBottom">
      <div class="container">
          <div class="row">
            <h6 class="content"> &#169; 2023 <a href="#" target="_blank">Real Time Neighborhood</a>  |  Designed by <a href="#" target="_blank">Open Doors Technology</a></h6>
          </div>
      </div>
  </section>
</footer>
