import { Component, Output, ViewChild, OnInit, AfterViewInit, OnDestroy,EventEmitter, ViewEncapsulation } from '@angular/core';
import { ModalComponent } from '@app/shared/ng2-bs3-modal';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import * as moment from 'moment';
import { AdminRestService } from '@app/admin/admin.service';
import { Subscription } from 'rxjs';

@Component({
  moduleId: module.id,  
  selector: 'rtn-modals-app-add-from-bookmark',
  templateUrl: './add-from-bookmark.component.html',
  styleUrls: ['./add-from-bookmark.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddFromBookmarkComponent implements OnInit {

  @ViewChild('Addbookmark_Modals') modal: ModalComponent;
  scheduleList: any = [];
  getbookmarklistSubscriber: Subscription;
  @Output() loadEvent = new EventEmitter<object>();

  constructor(
    private _loadingIndicatorService: LoadingIndicatorService,
    private _adminService: AdminRestService,
  ) { }

  ngOnInit() {
  }
  ngOnDestroy() {

  }
  open() {
    this.modal.open(); 
    this.getbookmarklist();
  }
  getbookmarklist(){
    this.scheduleList = [];
    this._loadingIndicatorService.start();        
    this._adminService.getbookmarklist().subscribe((success: any) => {
      this._loadingIndicatorService.stop();      
      this.scheduleList=success.arrlists;
    },(error: any) => {      
      this._loadingIndicatorService.stop();
    });
  } 
  addMoreSlot(id:any){
    this._loadingIndicatorService.start();        
    this._adminService.addfrombookmark(id).subscribe((success: any) => {
      this._loadingIndicatorService.stop();      
      this.modal.close();
      let params = {
        practiceId : success.practiceId,
        providerId: success.providerId,
        startTime: success.startTime
      }
      this.loadEvent.emit(params);
    },(error: any) => {      
      this._loadingIndicatorService.stop();
    });
  }
  close(){
    this.modal.close();
  }
}
