<modal #Fillopenslot_Modals class="appointment-new">
  <form #modalForm="ngForm" class="form">
    <div class="form-content">
      <div class="form-header">Fill Open Slot</div>
      <div class="form-info row">
        <div class=" col-lg-12 col-md-12 col-xs-12 no-padding-left mb-2">
          <table width="100%">
            <tbody>
              <tr>
                <td width="25%">Slot:</td>
                <td width="75%">{{scheduleListObject.pin_hour}}:{{scheduleListObject.pin_minute}} - {{scheduleListObject.pout_hour}}:{{scheduleListObject.pout_minute}}</td>                
              </tr>
              <tr>
                <td>Patient:</td>                  
                <td>
                  <div class="col-lg-12 col-md-12 col-xs-12 search">
                    <div class="input-group">
                      <input class="form-control form-input search-input" id="search-field-new-appointment" type="text"
                             placeholder="Enter Name or Phone Number" [(ngModel)]="scheduleListObject.patient_name"
                             (keyup)="onKeyUp()" autocomplete="off" [ngModelOptions]="{standalone: true}">
                      <div class="input-group-append">
                        <button class="search-button dropdown-toggle" [class.disabled]="searchDisabled"
                                [disabled]="searchDisabled" (click)="searchPatientClicked($event)"
                                role="button"
                                aria-haspopup="true" aria-expanded="false">
                          <span aria-hidden="true" class="fas fa-search"></span>
                        </button>
                      </div>
                      <div class="result dropdown-menu">                          
                        <div *ngFor="let p of patients" class="patient-info">
                          <div *ngIf="p.phone_mobile == null"
                               (click)="getInfoByNameClicked($event,p.id, p.first_name, p.last_name, p.dob, p.is_family)">
                            <span>{{ p.first_name }}</span>
                            <span>{{ p.last_name }}</span>
                            <span>- {{ p.dob }}</span>
                          </div>
                          <div *ngIf="p.phone_mobile != null"
                               (click)="getInfoByNumberClicked($event,p.id, p.phone_mobile, p.first_name, p.last_name)">
                            <span>{{ p.phone_mobile }}</span>
                            <span>{{ p.first_name }}</span>
                            <span>{{ p.last_name }}</span>
                          </div>
                        </div>
                      </div>
                    </div>          
                  </div>
                </td>
              </tr>
              <tr>
                <td>&nbsp;</td>
                <td>
                  <div class="form-check mr-3">
                    <label class="form-check-label">
                      <input type="checkbox" class="form-check-input" name="addtowaitlist" id="addtowaitlist" [(ngModel)]="scheduleListObject.addtowaitlist" value="1" />
                      <span class="lable-name">Add this patient to waitlist</span>
                    </label>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Arrived At:</td>
                <td>
                  <div class="col-4 rowcontent pl-1">                      
                    <div class="input-group select time-picker">
                      <select class="form-control" [(ngModel)]="scheduleListObject.arrived_hour" name="scheduleListObject.arrived_hour">
                        <option *ngFor="let h of hours" [ngValue]="h" [selected]="scheduleListObject.arrived_hour == h">{{ h }}</option>
                      </select>
                      <div class="input-group-select-icon">
                        <i class="fa fa-caret-down"></i>
                      </div>
                    </div>
                    <div>:</div>
                    <div class="input-group select time-picker">
                      <select class="form-control" [(ngModel)]="scheduleListObject.arrived_minute" name="scheduleListObject.arrived_minute">
                        <option *ngFor="let m of minutes" [ngValue]="m" [selected]="scheduleListObject.arrived_minute == m">{{ m }}</option>
                      </select>
                      <div class="input-group-select-icon">
                        <i class="fa fa-caret-down"></i>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Actual IN:</td>
                <td>
                  <div class="col-4 rowcontent pl-1">
                    <div class="input-group select time-picker">
                      <select class="form-control" [(ngModel)]="scheduleListObject.ain_hour" name="scheduleListObject.ain_hour">
                        <option *ngFor="let h of hours" [ngValue]="h" [selected]="scheduleListObject.ain_hour == h">{{ h }}</option>
                      </select>
                      <div class="input-group-select-icon">
                        <i class="fa fa-caret-down"></i>
                      </div>
                    </div>
                    <div>:</div>
                    <div class="input-group select time-picker" [ngClass]="{ 'disabled': true }">
                      <select class="form-control" [(ngModel)]="scheduleListObject.ain_minute" name="scheduleListObject.ain_minute">
                        <option *ngFor="let m of minutes" [ngValue]="m" [selected]="scheduleListObject.ain_minute == m">{{ m }}</option>
                      </select>
                      <div class="input-group-select-icon">
                        <i class="fa fa-caret-down"></i>
                      </div>
                    </div>                      
                  </div>
                </td>
              </tr>
              <tr>
                <td>Actual OUT:</td>
                <td>
                  <div class="col-4 rowcontent pl-1">                      
                    <div class="input-group select time-picker">
                      <select class="form-control" [(ngModel)]="scheduleListObject.aout_hour" name="scheduleListObject.aout_hour">
                        <option *ngFor="let h of hours" [ngValue]="h" [selected]="scheduleListObject.aout_hour == h">{{ h }}</option>
                      </select>
                      <div class="input-group-select-icon">
                        <i class="fa fa-caret-down"></i>
                      </div>
                    </div>
                    <div>:</div>
                    <div class="input-group select time-picker">
                      <select class="form-control" [(ngModel)]="scheduleListObject.aout_minute" name="scheduleListObject.aout_minute">
                        <option *ngFor="let m of minutes" [ngValue]="m" [selected]="scheduleListObject.aout_minute == m">{{ m }}</option>
                      </select>
                      <div class="input-group-select-icon">
                        <i class="fa fa-caret-down"></i>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>Cancel At:</td>
                <td>
                  <div class="col-4 rowcontent pl-1">                      
                    <div class="input-group select time-picker">
                      <select class="form-control" [(ngModel)]="scheduleListObject.c_hour" name="scheduleListObject.c_hour">
                        <option *ngFor="let h of hours" [ngValue]="h" [selected]="scheduleListObject.c_hour == h">{{ h }}</option>
                      </select>
                      <div class="input-group-select-icon">
                        <i class="fa fa-caret-down"></i>
                      </div>
                    </div>
                    <div>:</div>
                    <div class="input-group select time-picker">
                      <select class="form-control" [(ngModel)]="scheduleListObject.c_minute" name="scheduleListObject.c_minute">
                        <option *ngFor="let m of minutes" [ngValue]="m" [selected]="scheduleListObject.c_minute == m">{{ m }}</option>
                      </select>
                      <div class="input-group-select-icon">
                        <i class="fa fa-caret-down"></i>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td>No-Show:</td>
                <td>
                  <div class="col-4 rowcontent pl-1">                      
                    <div class="input-group select time-picker">
                      <select class="form-control" [(ngModel)]="scheduleListObject.n_hour" name="scheduleListObject.n_hour">
                        <option *ngFor="let h of hours" [ngValue]="h" [selected]="scheduleListObject.n_hour == h">{{ h }}</option>
                      </select>
                      <div class="input-group-select-icon">
                        <i class="fa fa-caret-down"></i>
                      </div>
                    </div>
                    <div>:</div>
                    <div class="input-group select time-picker">
                      <select class="form-control" [(ngModel)]="scheduleListObject.n_minute" name="scheduleListObject.n_minute">
                        <option *ngFor="let m of minutes" [ngValue]="m" [selected]="scheduleListObject.n_minute == m">{{ m }}</option>
                      </select>
                      <div class="input-group-select-icon">
                        <i class="fa fa-caret-down"></i>
                      </div>
                    </div>
                  </div>
                </td>
              </tr>
            </tbody>
          </table>    
          <div class="error-message">{{ errorMessage }}</div>      
        </div>
      </div>
          
    </div>
    <div class="button">
      <button type="button" class="btn btn-rtn-inv btn-90 btn-cancel" data-dismiss="modal" (click)="close()">Cancel</button>    
      <button type="submit" class="btn btn-rtn btn-90 float-right" (click)="submitData()" autofocus>Submit</button>        
    </div>
  </form>
</modal>