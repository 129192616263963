import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
//import { WaittimeMeterService } from '@app/waittime-meter/waittime-meter.service';
import { AdminRestService } from '@app/admin/admin.service';
import { AppointmentDetail } from '@app/wait-time/model/appointment-detail';
import { SystemInterval, SystemData, SystemEvent, SystemService } from '@app/shared/system';
import { Utils } from '@app/shared/utils';
import * as moment from 'moment';
import { Subscription } from 'rxjs';
import { ActivatedRoute, Router } from '@angular/router';
import { ModalComponent } from '@app/shared/ng2-bs3-modal';

import { AlertService} from '@app/shared/alert';

const _momentOptions = {
  week: {
    dow: 0,
    doy: 6// Sunday is the first day of the week.
  }
};
moment.updateLocale('en-US', _momentOptions);

declare let jQuery: any;


@Component({
  selector: 'rtn-modals-app-waittime-meter',
  templateUrl: './waittime-meter.component.html',
  styleUrls: ['./waittime-meter.component.scss']
})
export class WaittimeMeterComponent implements OnInit, OnDestroy {

  waitTimeDataList: any = [];
  waitTimeData: any = [];
  waitTimeMeterStyle: any;
  appointmentId: any = '';
  activityTime: any = '';
  appointmentDetail: AppointmentDetail = new AppointmentDetail();
  fetchWaitTimeSubscriber: Subscription;
  isFirefox = false;
  waitTimeInterval: any;
  waitTimeIntervalId: any;
  startTrigger = false;
  errorMessage: string = '';

  stars: number[] = [1, 2, 3, 4, 5];
  selectedValue: number = 0;
  currentRate = 0;
  review_text:any = '';
  responseError: any = { 
    review : null
  };
  reviewDetails : any={
    rating : 0,
    review : ''
  };
  iswmlgid : any = '';
  currentTimeStr:any='';
  wt_position = 0;
  @ViewChild('wait_timemeter_Modals') modal: ModalComponent;

  constructor(
    private Service: AdminRestService,
    public _systemData: SystemData,
    private _systemInterval: SystemInterval,
    //private _systemEvent: SystemEvent,
    //private _systemService: SystemService,
    public _utils: Utils,
    //private _activeRoute: ActivatedRoute,
    //private _router: Router,
    //private config: NgbRatingConfig,
    private _alertService : AlertService

  ) {

    this.waitTimeMeterStyle = new Object();

    //this.config.max = 5;
  }
  
 

  addClass(star : number) {
    //console.log("star", star); 
    //console.log("selectedvalue", this.selectedValue);
    let ab = "";
    for (let i = 0; i < star; i++) {
      //console.log("star i", star);
      ab = "starId" + i;
      document.getElementById(ab).classList.add("selected");
    }
  }
  removeClass(star : number) {
    //console.log("removestar", star);
    let ab = "";
    for (let i = star-1; i >= this.selectedValue; i--) {
      //console.log("star i", star);
      ab = "starId" + i;
      document.getElementById(ab).classList.remove("selected");
    }
  }

  open(id:any) {
    this.modal.open(); 
    this.appointmentId = id;
    //this.activityTime = time;
    //this.fetchAppointmentDetails();
    //this.currentTimeStr = created_time
    this.wt_position = 0;
    this.startWaitTimeInterval();
  }
  close(){
    this.modal.close();
    this.stopWaitTimeInterval();
  }
  ngOnInit() {
   
  }
  ngOnDestroy() {
    clearInterval(this.waitTimeIntervalId);
    if (this.fetchWaitTimeSubscriber) {
      this.fetchWaitTimeSubscriber.unsubscribe();
    }
  }
  calWaitTimeMeterDeg(xdeg: number) {
    let deg = xdeg ? xdeg : 0;
    if (deg < 0) {
      deg = 0;
    } else if (deg > 120) {
      deg = 123;
    }
    const _rotate = 'rotate(' + (deg * 1.5) + 'deg)';

    this.waitTimeMeterStyle = {
      '-ms-transform': _rotate,
      '-webkit-transform': _rotate,
      'transform': _rotate
    };
  }
  /*fetchAppointmentDetails() {
    var self = this; 
    this.Service.fetchAppointmentDetails(self.appointmentId, self.activityTime).subscribe((success: any) => {
      if (success.error_code === 'RC000') {
        self.appointmentDetail = success.appointments;
        if (success.appointments != null) {
          self.startWaitTimeInterval();
        } else {
          this.errorMessage = "Invalid request";
        }
      }
    });
  }*/
  
  displayWaitTime(id: number) {
    
    let dataNotFound = true;
    const now = new Date();
    let hour = now.getHours().toString();
    if (hour.length === 1) {
      hour = '0' + hour;
    }
    let minute = now.getMinutes().toString();
    if (minute.length === 1) {
      minute = '0' + minute;
    }
    const currentTime = hour + ':' + minute;
    /*if (!id) {
      id = this.appointmentDetail.id;
    }*/
    this.appointmentDetail.currentTime = currentTime;
    this.appointmentDetail.waitingTime = null;

    // console.log(currentTime);
    if (this.waitTimeData.length > 0) {
      for (let i = 0; i < this.waitTimeData.length; i++) {
          const data = this.waitTimeData[i];

         // console.log(data.id + ' - ' + id);

         // console.log(data);

          if (data.id === id) {
            this.calWaitTimeMeterDeg(data.time);
            if (data.time > 999) {
              data.time = 999;
            }
            this.appointmentDetail.waitingTime = data.time;
            this.appointmentDetail.is_active = data.is_active;
            if (this.appointmentDetail.waitingTime == null) {
              this.appointmentDetail.notice = 'Real time status meter is not available for this appointment anymore';
            } else {
              this.appointmentDetail.notice = data.message;
            }

            if (data.message.match('opt in')) {
              this.appointmentDetail.hasLink = true;
            }
            dataNotFound = false;    
            break;
          }       
      }

    } else {
      this.calWaitTimeMeterDeg(0);
      this.appointmentDetail.notice = 'Real time status meter is not available for this appointment anymore';
      this.appointmentDetail.waitingTime = null;
    }
    // console.log(dataNotFound);
    if (dataNotFound) {
      this.calWaitTimeMeterDeg(0);
      this.appointmentDetail.notice = 'Real time status meter is not available for this appointment anymore';
      this.appointmentDetail.waitingTime = null;
    }
    //console.log("TEST");
   // console.log(this.appointmentDetail);
  }
  startWaitTimeInterval() {
    this.stopWaitTimeInterval();
    var self = this;
    const getWaitTime = () => {
      this.Service.fetchWaitTimeByAppointment(this.appointmentId).subscribe((success: any) => {
        if (success.error_code === 'RC000') {
          self.waitTimeDataList = success.appointments;
          //console.log(self.waitTimeDataList.length);
          if (self.waitTimeDataList.length > 0) {
            self.waitTimeData = [];
            self.waitTimeData.push(self.waitTimeDataList[0]);
           // console.log(self.waitTimeData);

            self.currentTimeStr = self.waitTimeDataList[0].created_at;
           // console.log(self.currentTimeStr);
            self.displayWaitTime(self.appointmentId);
          }
          
          //console.log(self.activityTime);
         // self.activityTime = moment(self.activityTime, "YYYY-MM-DDTHH:mm:ss.SSSSZ").add(1, 'm').utc().format('YYYY-MM-DDTHH:mm:ss.SSSSZ');
         // console.log(self.activityTime);
        }
      });
    };

    //this.waitTimeInterval = setInterval(getWaitTime, this._systemInterval.appointment_fetchWaitTimePatient.duration);
    getWaitTime();
  }

  stopWaitTimeInterval() {
    if (this.waitTimeInterval) {
      clearInterval(this.waitTimeInterval);
      this.waitTimeInterval = null;
    }
  }  
  changeWaitmeter(){
    let self = this;
    let lenWt = self.waitTimeDataList.length;

    if (lenWt > self.wt_position) {
      self.wt_position++;

      self.waitTimeData = [];
      self.waitTimeData.push(self.waitTimeDataList[self.wt_position]);
      self.currentTimeStr = self.waitTimeDataList[self.wt_position].created_at;
      self.displayWaitTime(self.appointmentId);
    }
  }
  onKeyUp(error: string) {
    switch (error) {
        case 'review':
            this.responseError['review'] = null;
            break;            
    }
  }
}
