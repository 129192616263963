import { Component, Output, ViewChild, OnInit, AfterViewInit, OnDestroy,EventEmitter, ViewEncapsulation } from '@angular/core';
import { ModalComponent } from '@app/shared/ng2-bs3-modal';
import { LoadingIndicatorService } from '@app/shared/loading-indicator';
import * as moment from 'moment';
import { AdminRestService } from '@app/admin/admin.service';
import { Subscription } from 'rxjs';

@Component({
  moduleId: module.id,  
  selector: 'rtn-modals-app-add-custom-schedule',  
  templateUrl: './add-custom-schedule.component.html',
  styleUrls: ['./add-custom-schedule.component.scss'],
  encapsulation: ViewEncapsulation.None
})
export class AddCustomScheduleComponent implements OnInit {
  
  @ViewChild('AddSchedule_Modals') modal: ModalComponent;
  selectedPracticeId:any = 0;
  selectedProviderId:any = 0;
  selectedDateFrom:any = '';

  hours = ['00', '01', '02', '03', '04', '05', '06', '07', '08', '09', '10', '11', '12',
    '13', '14', '15', '16', '17', '18', '19', '20', '21', '22', '23'];
  minutes = ['00', '05', '10', '15', '20', '25', '30', '35', '40', '45', '50', '55'];

  fullminutes:any = [];

  
  errorMessage: string;
  errorProvider: string;

  //slotListObject: SlotList = new SlotList();
  //errorSlot: SlotList = new SlotList();
  //slotList: any = [];
  requireMessage: RequireMessage = new RequireMessage();

  scheduleList: any = [];
  scheduleListObject: ScheduleList = new ScheduleList();
  addCustomScheduleSubscriber: Subscription;
  @Output() loadEvent = new EventEmitter<object>();

  constructor(
    private _loadingIndicatorService: LoadingIndicatorService,
    private _adminService: AdminRestService,
  ) { }

  ngOnInit() {
  }
  ngAfterViewInit() {

  }
  ngOnDestroy() {

  }
  open(practiceID:any, providerID:any, dateFrom:any) {
    this.modal.open(); 
    this.selectedPracticeId = practiceID;
    this.selectedProviderId = providerID;
    this.selectedDateFrom = dateFrom;

    for (var i = 0; i < 60; i++) {
      let t1 = (i < 10) ? '0'+i : i;
      this.fullminutes.push(t1);
    }

    //this.slotList = [];
    this.scheduleList = [];

    //this.slotList.push(this.slotListObject);

    let scheduleListObject:any = new ScheduleList();  

    this.scheduleList.push(scheduleListObject);

    console.log(this.scheduleList);

    console.log('selectedPracticeId : ' + this.selectedPracticeId + '| selectedProviderId=' + this.selectedProviderId + '| selectedDateFrom=' + this.selectedDateFrom);
  }
  submitData(){
    
    this._loadingIndicatorService.start();
    if (this.addCustomScheduleSubscriber) {
        this.addCustomScheduleSubscriber.unsubscribe();
    }

    let newData = [];
    if (this.scheduleList.length > 0) {
      for (let i = 0; i < this.scheduleList.length; i++) {
        let tempdata = this.scheduleList[i];

        let itemEnd:any = { plan_in: '', plan_out: '', actual_in: '', actual_out: '', cancel_at: '', no_show: '', arrived_at:'', fill_slot: tempdata.fill_slot };
        // Plan in
        if (tempdata.pin_hour && tempdata.pin_minute && tempdata.pout_hour || tempdata.pout_minute) {
          itemEnd.plan_in = moment(this.selectedDateFrom, 'MMMM DD, YYYY').hour(tempdata.pin_hour).minute(tempdata.pin_minute).second(0).utc().format('YYYY-MM-DDTHH:mm:ssZZ');          
          itemEnd.plan_out = moment(this.selectedDateFrom, 'MMMM DD, YYYY').hour(tempdata.pout_hour).minute(tempdata.pout_minute).second(0).utc().format('YYYY-MM-DDTHH:mm:ssZZ');                    
        }
        // Actual
        if (tempdata.ain_hour && tempdata.ain_minute && tempdata.aout_hour || tempdata.aout_minute) {
          itemEnd.actual_in = moment(this.selectedDateFrom, 'MMMM DD, YYYY').hour(tempdata.ain_hour).minute(tempdata.ain_minute).second(0).utc().format('YYYY-MM-DDTHH:mm:ssZZ');          
          itemEnd.actual_out = moment(this.selectedDateFrom, 'MMMM DD, YYYY').hour(tempdata.aout_hour).minute(tempdata.aout_minute).second(0).utc().format('YYYY-MM-DDTHH:mm:ssZZ');                    
        }
        // Cancel
        if (tempdata.c_hour && tempdata.c_minute) {
          itemEnd.cancel_at = moment(this.selectedDateFrom, 'MMMM DD, YYYY').hour(tempdata.c_hour).minute(tempdata.c_minute).second(0).utc().format('YYYY-MM-DDTHH:mm:ssZZ');                    
        }
        // No show
        if (tempdata.n_hour && tempdata.n_minute) {
          itemEnd.no_show = moment(this.selectedDateFrom, 'MMMM DD, YYYY').hour(tempdata.n_hour).minute(tempdata.n_minute).second(0).utc().format('YYYY-MM-DDTHH:mm:ssZZ');             
        }
        // No show
        if (tempdata.arrived_hour && tempdata.arrived_minute) {
          itemEnd.arrived_at = moment(this.selectedDateFrom, 'MMMM DD, YYYY').hour(tempdata.arrived_hour).minute(tempdata.arrived_minute).second(0).utc().format('YYYY-MM-DDTHH:mm:ssZZ');             
        }
        
        newData.push(itemEnd);
      }
    }

    const params = {
      practiceId : this.selectedPracticeId,
      providerId : this.selectedProviderId,
      dateFrom : this.selectedDateFrom,
      schedules : this.scheduleList,
      newData : newData,
    };
    this.addCustomScheduleSubscriber = this._adminService.addCustomSchedule(params).subscribe(
        (success: any) => {
            this._loadingIndicatorService.stop();
            this.modal.close();
            this.loadEvent.emit(params);
        },
        (error: any) => {
            this._loadingIndicatorService.stop();
            if (error.error.error_code === 'RC040') {
                //this.error = error.error.error_fields;
            }
        });
  }
  close(){

  }  
  addMoreSlot() {

    //validate
    var isValid = true;
    if (this.scheduleList.length > 0) {
      for (let i = 0; i < this.scheduleList.length; i++) {
        let tempdata = this.scheduleList[i];
        console.log(tempdata.pin_hour);
        // Plan in
        if (tempdata.pin_hour== undefined || tempdata.pin_minute == undefined){
          isValid = false;
        }
        // Plan out
        if (tempdata.pout_hour== undefined || tempdata.pout_minute == undefined){
          isValid = false;
        }
       
        // Actual | cancel |noshow
        if (
          ((tempdata.ain_hour == undefined || tempdata.ain_minute == undefined) || (tempdata.aout_hour == undefined || tempdata.aout_minute == undefined)) && 
          (tempdata.c_hour == undefined || tempdata.c_minute == undefined) || (tempdata.n_hour == undefined || tempdata.n_minute == undefined)
        ) {
          isValid = false;          
        }
      }
    }
    /*if (!isValid){
      alert('Please fill the schedule information before add new row');
      return false;
    }*/
    if (this.scheduleList.length < 20) {     
      let scheduleListObject:any = new ScheduleList();
      this.scheduleList.push(scheduleListObject); 
    }
  }
  removeSlot(i : number) {
    this.scheduleList.splice(i,1);
    //console.log(this.scheduleList);
  }
  onFileSelected(event: any) {
    const file = event.target.files[0];

    
    /*if (file) {
      const reader = new FileReader();
      
      reader.onload = (e: any) => {
        const csvData = e.target.result;
        this.parseCSV(csvData);
      };
      
      reader.readAsText(file);
    }*/
  }

  parseCSV(csvData: string) {
    const lines = csvData.split('\n');
    const result1:any = [];
    let i = 0;
    lines.forEach((line) => {
      i++;
      if (i > 1){
        const row = line.split(',');
        result1.push(row);
      }
      
    });
    
    console.log(result1);
  }
}
export class RequireMessage {
  date: string;
  enddate: string;
  start_time: string;
  end_time: string;
  location_id: string;
}

export class ScheduleList {
  pin_hour: string;
  pin_minute: string;

  pout_hour: string;
  pout_minute: string;

  ain_hour: string;
  ain_minute: string;

  aout_hour: string;
  aout_minute: string;

  c_hour: string;
  c_minute: string;

  n_hour: string;
  n_minute: string;
  arrived_hour: string;
  arrived_minute: string;

  fill_slot:boolean=false;
}